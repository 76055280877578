@import 'styles/variables';
@import 'styles/common';

.about-page {
  @extend .page-container;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 0;
  overflow-y: auto;
  @include font-tiny;
  box-sizing: border-box;

  @media (min-width: 769px) {
    @include font-small;
  }

  @media (max-width: 768px) {
    padding: 6rem 0;
  }
}

.about-content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 0.75rem;
  }
}

.about-frame {
  @extend .content-frame;
  width: 100%;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: $border-radius;
  position: relative;
  z-index: 1;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1.5rem 0;
  }

  .section-content {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
}

.about-section {
  &.nested {
    margin-left: 20px;
  }
}

.section-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 1em;
  margin: 0 -1em;
  transition: background-color 0.3s ease;
  border-radius: $border-radius;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.chevron {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  transition: transform 0.3s ease;
  font-size: 14px;
  color: $color-white;

  &.expanded {
    transform: rotate(90deg);
  }
}

.section-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out, margin-top 0.3s ease-out;
  opacity: 1;
  margin-top: 15px;
  margin-left: 15px;
  padding-left: 10px;
  border-left: 1px dotted rgba(255, 255, 255, 0.3);
  width: 100%;

  &:not(.expanded) {
    max-height: 0;
    opacity: 0;
    margin-top: 0;
  }
}

.info-line {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;

  .info-key {
    flex: 1 0 50%;
    margin-right: 1em;
    white-space: normal;
  }

  .info-value {
    flex: 1 0 50%;
    color: rgba(255, 255, 255, 0.5);
    white-space: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }

  .external-link-icon {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    transition: color 0.3s ease;
    margin: 0;

    &::before {
      content: '[more info ';
    }

    &::after {
      content: ']';
    }

    &:hover {
      color: #FFFFFF;
    }
  }
}

.info-key {
  color: $color-white;
  margin-right: 5px;
}

.info-value {
  color: #999; // Medium grey color
}

.year-group {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  .year {
    margin-bottom: 1em;
    color: rgba(255, 255, 255, 0.5);
  }
}

// Remove the dots between years
.year-group:not(:last-child)::after {
  display: none;
}

.year-content {
  padding: 0;
  margin: 0;
}

.newsletter-form.top-form {
  margin-top: 4rem;
  padding: 1.5rem;
  background: rgba(9, 17, 21, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  h2 {
    @include font-tiny;
    margin: 0 0 0.5rem 0;
    color: $color-white;
    &::before {
      content: '[!]';
      color: #38DBD5;
      margin-right: 0.5rem;
    }
  }

  .input-group {
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 16px;
      background: #38DBD5;
      animation: blink 1s step-end infinite;
    }

    input {
      background: transparent;
      border: none;
      color: $color-white;
      font-size: 12px;
      padding: 0 0 0 16px;
      width: 100%;
      text-align: left;
      direction: ltr;

      &:focus {
        outline: none;
      }

      // Fix autofill styling
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px rgba(9, 17, 21, 0.95) inset !important;
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  .chat-history {
    margin-bottom: 0.5rem;
    max-height: 100px;
    overflow-y: auto;
    font-size: 12px;

    .chat-entry {
      margin-bottom: 0.25rem;

      &.command {
        color: rgba(255, 255, 255, 0.7);
      }

      &.response {
        color: #4A9EFF;
      }

      &.error {
        color: #ff4a4a;
      }
    }
  }
}

.form-message {
  margin-top: 8px;
  color: $color-white;
  text-transform: uppercase;
  letter-spacing: 1px;
  @include font-tiny;
}

a {
  color: $color-white;
  text-decoration: none;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}

.external-link-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-left: 4px;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: var(--project-theme-color, $color-theme-aops);
  }
}

// Media query for mobile devices
@media (max-width: 768px) {
  .newsletter-form.top-form {
    width: 90%;
    padding: 15px;
  }
}

h1, h2, h3 {
  font-weight: normal;
}

h1 {
  font-size: 1.3em;
  margin-bottom: 1em;
}

h2.section-header {
  font-size: 1em;
  margin: 0;
  padding: 12px 4px;
  border-radius: 2px;
}

h3.year {
  font-size: 0.9em;
  margin-bottom: 0.5em;
}

@keyframes blink {
  0%, 50% { opacity: 1; }
  51%, 100% { opacity: 0; }
}

.email-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  .mail-icon {
    margin-left: 8px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }

  &:hover {
    .mail-icon {
      opacity: 1;
    }
  }
}

// Special handling for press entries
.press-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    padding: 0 0.75rem;
  }
}

.press-item {
  position: relative;
  text-decoration: none;
  color: $color-white;
  display: block;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    .press-content {
      opacity: 1;
    }
  }
}

.press-gallery {
  position: relative;
  width: 100%;
  aspect-ratio: 3/4;
  border-radius: 4px;
  overflow: hidden;

  .image-container {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

.press-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);

  @media (max-width: 768px) {
    opacity: 1;
    padding: 0.75rem;
    justify-content: flex-end;
    background: linear-gradient(to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 30%,
      rgba(0, 0, 0, 0) 100%);
  }
}

.press-header {
  margin-bottom: 0.5rem;
}

.press-metadata {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  @include font-tiny;

  .press-magazine {
    color: $color-white;
    opacity: 0.9;
  }

  .press-date {
    opacity: 0.7;
  }

  .press-title {
    opacity: 0.8;
  }
}

.press-cta {
  @include font-tiny;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.5rem;

  .link-icon {
    font-size: 0.9em;
  }
}

// Special handling for person lists
.person-list {
  .info-line {
    .info-key {
      white-space: nowrap; // Keep single line for names
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.date-display {
  letter-spacing: 0.5px;
}

// Special handling for exhibitions and awards
.exhibitions-list, .awards-list {
  .info-line {
    .info-key {
      white-space: normal; // Allow text wrapping
      padding-right: 2em;
    }
  }
}

// Keep the ellipsis only for other sections where needed
.person-list {
  .info-line {
    .info-key {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.project-links {
  margin-left: 0;
}

.project-link {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #FFFFFF;
  }
}

.info-value-content {
  display: flex;
  flex-direction: column;
  gap: 0.3em;

  .exhibition-type,
  .exhibition-location,
  .exhibition-date,
  .exhibition-time {
    color: rgba(255, 255, 255, 0.5);
  }
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
}

.muses-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
}

.muse-card {
  position: relative;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.expanded {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    background: rgba(255, 255, 255, 0.05);

    .muse-image {
      width: 273px;  // Fixed width when expanded
      aspect-ratio: 15/22;
      padding-bottom: 0;
      position: relative;
    }

    .muse-content {
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem 2rem 0;
    }

    .muse-description {
      display: block;
      margin-top: 1rem;
      line-height: 1.6;
      color: rgba(255, 255, 255, 0.9);
    }

    .muse-instagram {
      display: block;
      margin-top: auto;
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: $color-white;
      }
    }
  }

  .muse-image {
    width: 100%;
    aspect-ratio: 15/22;
    position: relative;
    overflow: hidden;
    flex: none;
    border-radius: $border-radius $border-radius 0 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .muse-content {
    padding: 1rem;
    flex: 1;
  }

  .muse-header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .muse-name {
    font-size: 1em;
    color: $color-white;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .muse-role {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
  }

  .muse-description {
    display: none;
  }

  .muse-instagram {
    display: none;
  }
}

// Remove modal styles since we're not using them anymore
.muse-details, .modal-backdrop {
  display: none;
}

@media (max-width: 1200px) {
  .muse-card.expanded {
    .muse-image {
      width: 220px;
    }
  }
}

@media (max-width: 768px) {
  .muse-card {
    &.expanded {
      grid-template-columns: 1fr;
      gap: 1rem;

      .muse-image {
        width: 100%;
        max-width: 273px;
        margin: 0 auto;
      }

      .muse-content {
        padding: 1rem;
      }
    }
  }
}

.events-list {
  position: relative;
  z-index: 1;

  .year-content {
    padding: 0;
    margin: 0;
  }

  .year {
    text-align: center;
    position: relative;
    margin: 2rem 0;
    color: rgba(255, 255, 255, 0.5);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      z-index: -1;
    }

    span {
      background: var(--project-theme-color, #091115);
      padding: 0 1rem;
    }
  }

  .event-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    margin: 1rem 0;
    box-sizing: border-box;
    padding: 0 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0 0.75rem;
    }

    .event-text-content {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      min-width: 0; // Prevent text overflow
      overflow-wrap: break-word; // Allow long words to break

      .event-metadata {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .event-date-location {
          display: flex;
          gap: 0.5rem;
          @include font-tiny;
          color: rgba(255, 255, 255, 0.8);
          font-family: var(--mono-font);
          flex-wrap: wrap; // Allow wrapping for long location names
        }

        .event-title {
          @include font-medium;
          color: $color-white;
          text-decoration: none;
          hyphens: auto; // Enable hyphenation for long titles
          word-break: break-word;
        }
      }

      .event-description {
        @include font-tiny;
        line-height: 1.6;
        white-space: pre-line;
        color: rgba(255, 255, 255, 0.8);
        overflow-wrap: break-word;
      }

      .event-link {
        @extend .external-link;
        margin-top: 1rem;
        @include font-tiny;
        opacity: 0.8;
        align-self: flex-start;
      }
    }

    .event-gallery {
      width: 100%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      @media (max-width: 768px) {
        order: -1; // Move gallery above text on mobile
      }

      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        .image-container {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 4px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }
      }

      .swiper-pagination {
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        gap: 4px;

        .swiper-pagination-bullet {
          width: 6px;
          height: 6px;
          background: rgba(255, 255, 255, 0.5);
          opacity: 1;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          cursor: pointer;

          &-active {
            background: white;
            width: 12px;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
