@import 'variables';

// Global styles
html {
  scroll-behavior: smooth;
}

// Global heading styles
h1, h2, h3, h4, h5, h6, span {
  font-family: var(--mono-font);
  letter-spacing: 0.5px;
  margin: 0;
  color: white;
  line-height: 120%;
}

// Specific heading styles for make-reservation
.make-reservation h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

// Base button styles
button,
.btn,
.action-button,
.new-booking-button {
  font-family: var(--mono-font);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.primary {
    background: white;
    color: black;
    border: none;

    &:hover {
      background: rgba(255, 255, 255, 0.9);
    }
  }

  &.danger {
    color: #ff4444;
    border-color: rgba(255, 68, 68, 0.2);

    &:hover {
      background: rgba(255, 68, 68, 0.1);
      border-color: #ff4444;
    }
  }
}

// Common form styles
input,
select,
textarea {
  width: 100%;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-family: var(--mono-font);
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 14px;
  }

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;

  option {
    background: #1a1a1a;
    color: white;
    padding: 12px;
  }
}

// Common labels
label {
  display: block;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--mono-font);
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 6px;
  }
}

// Toast notifications
.error-toast,
.success-toast {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 24px;
  border-radius: 8px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: auto;
  text-align: center;
  animation: slideDown 0.3s ease-out;
  font-family: var(--mono-font);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.error-toast {
  background: rgba(255, 68, 68, 0.95);
  color: white;
}

.success-toast {
  background: rgba(40, 167, 69, 0.95);
  color: white;
}

// Common animations
@keyframes slideDown {
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

// Loading states
.loading {
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--mono-font);
  font-size: 16px;
  letter-spacing: 0.5px;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0a0a0a;
  color: white;
  min-height: 100vh;
}

// Only apply overflow control to specific containers that need it
.modal-container,
.splash-container {
  overflow: hidden;
}

// Common page layout
.page-container {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem 0;
  overflow-y: auto;
  box-sizing: border-box;
  @include font-tiny;

  @media (min-width: 769px) {
    @include font-small;
  }

  @media (max-width: 768px) {
    padding: 6rem 0;
  }
}

// Common content frame
.content-frame {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: $border-radius;
  padding: 3rem;

  @media (max-width: 768px) {
    width: auto;
    margin: 0;
    padding: 1.5rem;
  }
}

// Common link styles
.external-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-family: var(--mono-font);
  font-size: 0.9em;
  transition: all 0.3s ease;

  &:hover {
    color: $color-white;

    .link-icon {
      transform: translate(2px, -2px);
    }
  }

  .link-icon {
    font-size: 1em;
    transition: transform 0.3s ease;
  }
}

// Common back link
.back-link {
  display: block;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-family: var(--mono-font);
  font-size: 0.9em;
  margin: 4rem auto 0;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius;
  transition: all 0.3s ease;
  background: rgba(9, 17, 21, 0.95);
  backdrop-filter: blur(10px);
  max-width: 800px;

  &:hover {
    color: $color-white;
    border-color: rgba(255, 255, 255, 0.2);
  }
}