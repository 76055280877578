@import 'styles/variables';

.gallery-view {
  overflow: hidden !important;

  body {
    overflow: hidden !important;
    width: 100%;
    height: 100%;
  }
}

.gallery-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: var(--project-theme-color, $color-theme-aops);

  &::-webkit-scrollbar {
    display: none;
  }
}

.gallery-slide {
  width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-container {
  width: 67.5vh;
  width: calc(67.5 * var(--vh, 1vh));
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-slide.about-slide .image-container {
  width: 100vw; // Full width for ABOUT images

  // Add gradient overlay
  &:before {
    content: '';
    position: absolute;
    left: -5vw; // Extend slightly beyond the container
    top: -2rem;
    width: calc(100% + 5vw);
    height: calc(100% + 4rem);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 60%, rgba(0, 0, 0, 0) 100%);
    z-index: 2;
    pointer-events: none;
  }
}

.gallery-slide.alluvial-intro .image-container {
  background-position: 60% center; // Adjust this value to move the background more to the right
}

.gallery-slide.alluvial-intro .image-container img {
  object-position: 60% center; // Adjust this value to move the image more to the right
}

.gallery-navigation {
  position: fixed;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    pointer-events: none;
  }
}

.gallery-nav-item {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.4em;
  transition: all 0.3s ease;

  &:hover {
    .gallery-nav-dot {
      transform: scale(1.4);
    }
    .gallery-nav-label {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.active {
    .gallery-nav-dot {
      width: 5px;
      height: 5px;
      transform: scale(1.67);
      background-color: $color-white;
    }
    .gallery-nav-label {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.gallery-nav-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba($color-white, 0.3);
  transition: all 0.3s ease;
  margin-left: 0.5rem;
  transform-origin: center;
}

.gallery-nav-label {
  @include font-tiny;
  color: $color-white;
  opacity: 0;
  transform: translateX(10px);
  transition: all 0.3s ease;
  white-space: nowrap;
  text-align: right;
}

.about-text-container {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 25%;
  z-index: 10;

  @media (max-width: 768px) {
    width: 50%;
  }
}

.about-text {
  color: $color-white;
  @include font-tiny;
  text-align: left;
  padding: 0;
  margin: 0 0 8px 0; // Add margin bottom for button
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;

  &.expanded {
    -webkit-line-clamp: unset;
    max-height: 75vh;
    overflow-y: auto;
  }

  @media (min-width: 769px) {
    @include font-small;
  }

  .credits-label {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  h1 {
    @include font-small;
    margin: 0 0 1em 0;
    color: $color-white;
    &::before {
      content: '//';
      margin-right: 0.5em;
    }
  }
}

.credits-button-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.credits-button {
  @include font-tiny;
  background: none;
  border: none;
  color: $color-white;
  cursor: pointer;
  padding: 4px 0;
  text-transform: uppercase;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  margin-top: 8px; // Add some space between text and button

  @media (min-width: 769px) {
    @include font-small;
  }

  &:before {
    content: '>>';
    margin-right: 5px;
    transition: transform 0.1s ease-in-out;
  }

  &:hover {
    transform: translateX(5px);
  }
}

.credits-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  display: -webkit-box;
  -webkit-line-clamp: 10; // Show 10 lines
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  &.visible {
    opacity: 1;
    display: block; // Reset display to show full content
    max-height: 1000px;
    -webkit-line-clamp: unset;
  }
}

.credits-separator {
  margin: 0 5px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.credits-separator.show,
.credits-button.show {
  opacity: 1;
  transform: translateY(0);
}

// Media query for mobile devices
@media (max-width: 768px) {
  .gallery-slide.alluvial-intro .image-container {
    background-position: 80% center; // Move further right on mobile
  }

  .gallery-slide.alluvial-intro .image-container img {
    object-position: 80% center; // Move further right on mobile
  }
}

.gallery-slide.alluvial-intro .image-container img {
  filter: brightness(50%);
}

.image-description {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.image-caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: none; // Hide the caption in regular web view
}

.hidden-content {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
