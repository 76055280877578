@import 'styles/variables';
@import 'styles/common';

// Override heading styles
h1, h2, h3, h4, h5, h6 {
  line-height: 120% !important;
}

.event-page {
  width: 100vw;
  height: 100vh;
  background-color: cover;
  background-position: center;
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 6rem 2rem 0;

  @media (max-width: 768px) {
    padding: 6rem 1rem 0;
  }
}

.event-content {
  box-sizing: border-box;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-bottom: 1rem;
  }

  .image-container {
    border-radius: 4px;
    width: 100%;
    height: fit-content;

    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      object-position: center;
    }
  }
}

.event-navigation {
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: 0 auto 2rem;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 1rem;
  }

  .event-nav-link {
    pointer-events: auto;
    width: 200px;
    opacity: 0.7;
    transition: all 0.3s ease;
    text-decoration: none;
    color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
      width: 140px;
      gap: 0.5rem;
      padding: 0.5rem;
    }

    &:hover {
      opacity: 1;
      border-color: rgba(255, 255, 255, 0.3);
      background: rgba(0, 0, 0, 0.3);
    }

    &.prev {
      margin-right: auto;
      text-align: right;

      .nav-content {
        text-align: right;
      }
    }

    &.next {
      margin-left: auto;
      text-align: left;

      .nav-content {
        text-align: left;
      }
    }

    .nav-image {
      width: 100%;
      height: 120px;
      flex-shrink: 0;
      border-radius: 4px;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.05);

      @media (max-width: 768px) {
        height: 80px;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .nav-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media (max-width: 768px) {
        gap: 0.25rem;
      }
    }

    .nav-label {
      @include font-tiny;
      text-transform: uppercase;
      font-family: var(--mono-font);
      letter-spacing: 0.05em;
      opacity: 0.7;
    }

    .nav-title {
      @include font-tiny;
      color: $color-white;
    }

    .nav-date {
      @include font-tiny;
      opacity: 0.7;
      font-family: var(--mono-font);
      display: block;
    }
  }
}

.event-header {
  box-sizing: border-box;
}

.event-description {
  @include font-tiny;
  line-height: 1.6;
  white-space: pre-line;
  color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  width: 100%;
}

.event-gallery {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin: 1rem 0;

  .swiper-slide {
    .image-container {
      width: 100%;
      min-height: 300px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
        border-radius: 4px;
      }
    }
  }


  .event-swiper {
    .swiper-wrapper {
      align-items: flex-start;
    }

    .swiper-slide {
      .image-container {
        min-height: 300px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          min-height: 300px;
          display: block;
          object-fit: contain;
          border-radius: 4px;
        }
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: 16px;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      gap: 4px;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: rgba(255, 255, 255, 0.5);
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;

        &-active {
          background: white;
          width: 12px;
          border-radius: 3px;
        }
      }
    }
  }
}

.event-metadata {
  box-sizing: border-box;
  width: 100%;

  .event-date-location {
    display: flex;
    gap: 0.5rem;
    @include font-tiny;
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--mono-font);
  }

  .event-title {
    @include font-large;
    font-weight: normal;
    color: $color-white;
    text-decoration: none;
    transition: color 0.3s ease;

    @media (max-width: 768px) {
      font-size: 18px;
      margin: 0.25rem 0 0.75rem;
    }
  }

  .event-subtitle {
    @include font-tiny;
    font-style: italic;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.4;
    margin-top: 0.25em;
  }
}

.event-link {
  @extend .external-link;
  margin-top: 1rem;
  @include font-tiny;
  opacity: 0.8;
  align-self: flex-start;
}

